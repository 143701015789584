:root {
    --bs-primary: #34B0D7;  /* Bootstrap's default blue */
  }

.projects-list {
    margin-top: 1rem;
  }
  
  .project-card {
    margin-bottom: 1rem;
    background: white;
    overflow: hidden;
    cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
  }
  
  .project-carousel {
    width: 100%;
    padding: 0;
    border-radius: 0;
    position: relative;
  }
  
  .project-carousel img {
    object-fit: cover;
    aspect-ratio: 1/1;
    width: 100%;
    height: auto;
  }
  
  .project-title-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    padding: 2rem 1rem 1rem;
    z-index: 2;
  }
  
  .project-title-overlay h3 {
    color: #fff;
    margin: 0;
    font-size: 1.5rem;
  }
  
  .project-info {
    padding: 1rem;
  }
  
  .project-info h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  .latest-update {
    margin: 0.5rem 0 0;
    color: #666;
    font-size: 0.9rem;
  }
  
  .right-align {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .project-image {
    border-radius: 10px;
    aspect-ratio: 1;
    width: 100%;
    height: auto !important;
  }
  
  .section-title {
    padding: 1rem;
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .status-pill {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    font-size: 0.8rem;
    font-weight: 500;
    z-index: 1;
    margin-right: 1rem;
    margin-top: 1rem;
  }
  
  .status-pill.active {
    background-color: #28a745;
    color: white;
  }
  
  .project-page {
    min-height: 100vh;
    background-color: #fff;
  }
  
  .project-content {
    padding-top: 40px; /* Adjust based on your header height */
  }
  
  .status-badge {
    padding: 6px 12px;
    border-radius: 16px;
    font-size: 0.9em;
    font-weight: 500;
    text-transform: capitalize;
  }
  
  .status-badge.active {
    background-color: #e3f2fd;
    color: #1976d2;
  }
  
  .project-description {
    color: #666;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

.preview-project-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
  
  .metrics-container {
    margin: 0 -8px;
  }
  
  .metric-tile {
    background: #f8f9fa;
    border-radius: 12px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    aspect-ratio: 1/1;
    position: relative;
  }
  
  .metric-icon {
    font-size: 1.5rem;
    color: var(--bs-primary);
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  
  .metric-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-top: auto;
  }
  
  .metric-value {
    font-size: 1.7rem;
    font-weight: bold;
    color: #333;
  }
  
  .metric-name {
    font-size: 1rem;
    color: #666;
  }
    
  /* Add new style for the main title */
  .project-main-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .project-started {
    color: #666;
    font-size: 0.9rem;
    margin-top: 0.25rem;
  }
  
  /* Add new styles for project list carousel */
  .project-card .project-carousel {
    margin: 1rem;
    width: calc(100% - 2rem);  /* Adjust width to account for margins */
  }
  
  .project-card .project-image {
    border-radius: 8px;  /* Add rounded corners to images in project list */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  /* Optional: subtle shadow for photo-like effect */
  }
  
  .timeline {
    position: relative;
    padding: 20px 0;
    padding-bottom: 1px;
  }
  
  .timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    height: 100%;
    width: 2px;
    background: var(--bs-gray-400);
  }
  
  .timeline-item {
    position: relative;
    margin-bottom: 20px;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
  }
  
  .timeline-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .timeline-marker {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--bs-primary);
    border: 2px solid white;
    z-index: 1;
    margin-left: -32px;
  }
  
  .timeline-date {
    font-size: 0.9rem;
    color: var(--bs-gray-600);
    background: white;
    padding: 0 8px;
    line-height: 16px;
  }
  
  .timeline-content {
    background: white;
    border-radius: 4px;
    width: 100%;
  }
    .timeline-content .post {
        padding: 0px;
        margin-top: 8px;
        border-bottom: none;
    }

  .project-start-event {
    padding: 8px 0px;
    color: #666;
    display: flex;
    align-items: center;
    gap: 8px;
  }
    .project-start-event span {
        font-style: italic;
    }
  
  .gallery-content {
    padding: 0rem;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .gallery-item {
    position: relative;
    aspect-ratio: 1;
  }
  
  .gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .remove-photo-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
  }
  
  .remove-photo-btn:hover {
    background: rgba(0, 0, 0, 0.7);
  }
  
  .focused-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .focused-image-container {
    position: relative;
    max-width: 90vw;
    max-height: 90vh;
  }
  
  .focused-image-container img {
    max-width: 100%;
    max-height: 90vh;
    object-fit: contain;
  }
  
  .close-button {
    position: absolute;
    top: -40px;
    right: 0;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 8px;
  }
  
  .close-button:hover {
    opacity: 0.8;
  }
  
  .metric-input-group {
    background: #f8f9fa;
    padding: 12px;
    border-radius: 8px;
  }
  
  .metric-input-group .form-control {
    font-size: 14px;
  }
  
  .metric-input-group .btn-danger {
    padding: 4px 8px;
  }
  
  .metric-item {
    margin-top: 16px;
    border: solid 1px #DEDEE4;
    border-radius: 10px;
    padding: 16px;
  }
  
  .icon-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  
  .icon-option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border: 1px solid #DEDEE4;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .icon-option:hover {
    background-color: #f8f9fa;
  }
  
  .icon-option.selected {
    background-color: #e3f2fd;
    border-color: #1976d2;
    color: #1976d2;
  }
  
  .icon-display {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border: 1px solid #DEDEE4;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 16px;
  }
  
  .icon-display:hover {
    background-color: #f8f9fa;
  }
  
  .icon-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: slideUp 0.3s ease-out;
  }
  
  .icon-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .icon-popup-header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: white;
    border-bottom: 1px solid #DEDEE4;
    z-index: 1;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  
  .icon-popup-close {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 8px;
  }
  
  .icon-categories {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    -webkit-overflow-scrolling: touch;
  }
  
  .icon-category {
    margin-bottom: 1.5rem;
  }
  
  .icon-category:last-child {
    margin-bottom: 0;
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  /* Project Gallery Page specific styles */

  .gallery-page-content {
    padding: 0rem;
  }
  
  .gallery-page-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;  /* Two columns */
    gap: 0.5rem;
    padding: 0.5rem;
  }
  
  .gallery-page-item {
    position: relative;
    aspect-ratio: 1;
  }
  
  .gallery-page-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  
  /* Edit Page Gallery specific styles */
  .edit-gallery-content {
    padding: 0rem;
  }
  
  .edit-gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .edit-gallery-item {
    position: relative;
    aspect-ratio: 1;
  }
  
  .edit-gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .form-check.form-switch {
    padding-left: 2.5em;
  }
  
  .form-check-input {
    width: 3em;
    height: 1.5em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out;
  }
  
  .form-check-input:checked {
    background-position: right center;
    background-color: #0d6efd;
    border-color: #0d6efd;
  }