.popupMenu {
    z-index: 1000;
    position: fixed;
    bottom: 0;
    width: 100vw;
    max-width: 700px;
}

.bg-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
}

.menu-container {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100vw;
    max-width: 700px;
    padding-bottom: 40px;
    padding-top: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
