.feed {
    margin-bottom: 72px;
}

.post {
    background: white;
    padding: 20px 8px;
    border-bottom: solid 1px #DEDEE4;
    text-align: left;
  }

  .post-milestone {
    background: rgb(204,255,243);
    background: linear-gradient(231deg, rgba(204,255,243,1) 0%, rgba(227,230,255,1) 89%);
    text-align: center;
    padding-top: 0px;
    border-bottom: none;
  }
    .milestone-post-category {
        border-bottom: none;
    }

  .post-container {
    position: relative;
  }

  /* .milestone-content {
    background: rgb(204,255,243);
background: linear-gradient(231deg, rgba(204,255,243,1) 0%, rgba(227,230,255,1) 48%);
  } */

  .post .menu-icon {
    position: absolute;
    top: 0px; /* Adjust as needed */
    right: 0px; /* Adjust as needed */
    color: #DEDEE4;
  }
  
    .post-category {
        display: flex;
        align-items: center; /* Aligns items vertically in the center */
        margin-bottom: 16px;
        font-size: 0.9em;
        color: #333;
    }
  
  .post-category-circle {
    width: 20px; /* Circle size */
    height: 20px; /* Circle size */
    border-radius: 50%; /* Makes the div circular */
    margin-right: 8px; /* Space between the circle and the text */
  }
  
  .post-title {
    font-size: 1.5em;
    font-weight: 500;
    color: #333;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  
  .post-content {
    color: #333;
    margin-bottom: 20px;
    /* font-family:'Times New Roman', Times, serif; */
  }

    .post-image img {
        width: calc(100% + 40px); /* Assuming padding is 8px on each side */
        margin-left: -20px; /* Offset the left padding */
        margin-right: -20px; /* Offset the right padding */    height: auto; /* Maintain aspect ratio */
        margin-bottom: 8px; /* Space between the image and the title */
    }  

    .post-milestone .post-image img {
        -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
    }
  
  .post-footer {
    font-size: 0.85em;
    color: #a0a0a0;
    display: flex;
    /* justify-content: space-between; */
  }
    .post-milestone .post-footer {
        color: #575757;
        padding-top: 32px;
    }
    .post-footer span {
        margin-right: 16px;
    }

    .milestone-tag {
        background-color: #C8EDF5;
        color: #34B0D7;
        font-weight: bold;
        padding: 8px 28px;
        margin-top: 24px;
        display: inline-block;
        border-radius: 18px;
    }

    .milestone-img-holder {
        padding: relative;
    }

    .icon-holder {
        margin-top: 16px;
     }

    .milestone-img-holder .icon-holder {
       margin-top: -33px;
    }

    .icon-circle {
        width: 50px; /* Circle size */
        height: 50px; /* Circle size */
        border-radius: 50%; /* Makes the div circular */
        background-color: #fff;
        padding: 8px;
        display: inline-block;
        -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
    }

.loading-overlay {
    pointer-events: none;
    opacity: 0.7;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.title span {
    padding: 0 8px;
    transition: color 0.3s ease;
}

.title span:hover {
    opacity: 0.8;
}

.post-project-tag {
    display: inline-block;
    background-color: #f5f5f5;
    font-size: 0.9em;
    padding: 4px 12px;
    border-radius: 16px;
    margin-bottom: 8px;
}
  .milestone-content .post-project-tag {
    background-color: #fff;
    margin-bottom: 0px;
  }

.post-carousel {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 8px;
}

.post-carousel .carousel-item {
    width: 100%;
}

.post-carousel .post-image {
    width: 100%;
}

.post-carousel .post-image img {
    width: 100%;
    margin: 0; /* Reset margins since the carousel is already positioned correctly */
}

.post-carousel .carousel-control-prev,
.post-carousel .carousel-control-next {
    width: 10%;
    opacity: 0.8;
    background: rgba(0, 0, 0, 0.2);
}

.post-carousel .carousel-control-prev:hover,
.post-carousel .carousel-control-next:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
}

.post-carousel .carousel-indicators {
    margin-bottom: 0.5rem;
}

.post-carousel .carousel-indicators button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 4px;
}

.post-carousel.carousel .carousel-item {
  transition: transform .32s ease-in-out !important;  /* Default is 0.6s */
}