.App {
  max-width: 700px;
  margin: 0 auto;
}

p {
  font-size: 1em;
}

img {
  width: 100%;
}

input[type="text"] {
  width: 100%;
  padding: 4px 8px;
  border: solid 1px #DEDEE4;
  border-radius: 5px;
}
  input[type="text"]:focus {
    outline: none;
  }

textarea {
  width: 100%;
  padding: 10px;
  border: none;
  height: calc(35vh - 80px);
}
  textarea:focus {
    outline: none;
  }

  textarea.outline {
    border: solid 1px #DEDEE4;
  }

.primary-btn-fill {
  background-color: #0984e3;
}

.primary-text-color {
  color: #1799F6;
}

.primary-full-color {
  background-color: #1799F6;
  width: 100%;
  padding: 15px 0px;
  color: white;
  border: none;
  border-radius: 10px;
}

.content-below-nav {
  margin-top: 47px;
  margin-bottom: 85px;
}
  .remove-top-margin {
    margin-top: 0px !important;
  }

.page-header-nav {
  text-align: center;
  padding-top: 8px;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  max-width: 700px;
  background-color: #fff;
}
  .page-header-nav .title {
    font-size: 1.1em;
    padding-top: 5px;
  }

.nav {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 0px;
  padding-bottom: 36px;
  color: #95969A;
  position: fixed;
  bottom: 0;
  border-top: solid 1px #DEDEE4;
  width: 100%;
  max-width: 700px;
  font-size: 0.7em;
  z-index: 500;
}
  .nav .nav-btn .icon {
    font-size: 1.7em;
    color: #95969A;
  }

.nav .nav-btn.active {
  color: #000000;
}

.profile-header {
  text-align: left;
  padding-top: 24px;
  padding-bottom: 8px;
  padding-left: 8px;
  position: relative; /* or absolute, if needed based on your layout */
  z-index: 10; /* Ensure it's above other content when scrolled to top */
}
  header h1 {
    padding-top: 24px;
    font-size: 1.7em;
  }

.circ-image {
  width: 100%;
  border-radius: 50%;
}

.mid-nav {
  border-bottom: #DEDEE4 solid 1px;
  text-align: left;
  padding-left: 8px;
  padding-top: 0px;
  position: sticky;
  z-index: 5;
  background: white;
}

.sticky-nav {
  position: fixed;
  top: 47px;
  left: 0;
  right: 0;
  max-width: 700px;
  margin: 0 auto;
  z-index: 999;
}

  .mid-nav.top {
    top: 0;
  }

  .mid-nav.with-header {
    top: 47px; /* Adjust based on the height of the header */
  }

  .mid-nav .nav-btn {
    color: #95969A;
    display: inline-block;
    border-bottom: none;
    margin-right: 30px;
    margin-bottom: -1px; /* Makes it so that the active border overlaps the bottom border line */
    padding-bottom: 12px;
    font-size: 0.95em;
  }
    .mid-nav.top .nav-btn {
      padding-top: 16px;
    }
    .mid-nav.with-header .nav-btn {
      padding-top: 12px;
    }
    .mid-nav .nav-btn.active {
      border-bottom: 2px solid #333;
      font-weight: bold;
      color: #333;
    }

.circ-btn {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
  text-align: center;
  padding-top: 4px;
  display: inline-block;
}

.secondary-wide-btn {
  background-color: #ffffff;
  text-align: center;
  padding: 6px 0px;
  border: 1px solid #95969A;
  border-radius: 8px;
  margin-top: 16px;
  font-size: 0.9em;
  color: #333;
}

.right-align {
  display: flex;
  flex-direction: row-reverse; 
}

.flush-bottom {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.vert-spacer {
  padding-top: 24px;
}

.dot-spacer {
  font-size: 0.23em;
  display: inline-block;
  color: #333;
  vertical-align: middle;
  padding: 0px 8px 0px 4px;
}

.subtext-gray {
  color:#95969A;
}

.color-1 {
  background-color: #3867d6; /* Circle color */
}

.color-2 {
  background-color: #45aaf2; /* Circle color */
}

.color-3 {
  background-color: #2bcbba; /* Circle color */
}

.color-4 {
  background-color: #26de81; /* Circle color */
}

.color-5 {
  background-color: #fed330; /* Circle color */
}

.color-6 {
  background-color: #fd9644; /* Circle color */
}

.color-7 {
  background-color: #D980FA; /* Circle color */
}

.color-8 {
  background-color: #4F9F39; /* Circle color */
}

.color-selection {
  display: inline-block;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 24px;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  background-color: #ffffff;
}
  .color-selection.selected {
    border: 4px solid #c9dbe1;
  }

  .color-selection .post-category-circle {
    margin-top: 3px;
    margin-left: 3px;
  }

@keyframes swipeLeft {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes swipeRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.active-content {
  animation: swipeLeft 0.3s ease forwards;
}

.hidden-content {
  display: none;
}

/* Add this class dynamically if the previous tab was to the left of the current tab */
.tab-enter-left {
  animation: swipeLeft 0.3s ease forwards;
}

/* Add this class dynamically if the previous tab was to the right of the current tab */
.tab-enter-right {
  animation: swipeRight 0.3s ease forwards;
}

.loading-notification-bar {
  background-color: #e9f1f6;
  text-align: left;
  padding: 15px 15px;
  margin-top: 49px;
  color: #439cd7;
}
  .loading-notification-bar svg {
    margin-right: 6px;
    color: #73b3de;
  }

.new-user-page {
  margin-top: 20vh;
}
  .new-user-page h1 {
    margin-bottom: 12px;
  }


  .toggle-container {
    background-color: #F0F0F4;
    border-radius: 24px;
    padding: 4px;
    display: inline-flex;
    margin-top: -10px;
}

.toggle-option {
    padding: 0px 16px;
    border-radius: 20px;
    flex: 1;
    text-align: center;
    transition: all 0.3s ease;
    cursor: pointer;
}

.toggle-option.active {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.toggle-option:not(.active) {
    color: #666;
}

.toggle-option:hover:not(.active) {
    background-color: rgba(255,255,255,0.3);
}