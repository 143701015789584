.landing-header {
  background: linear-gradient(180deg, #FFFFFF 0%, #13B2B3 100%);
  padding: 48px 0;
  color: white;
  margin-bottom: 32px;
  text-align: left;
}

.landing-header img {
  max-width: 90px;
  margin-top: 24px;
  margin-bottom: 16px;
}

.landing-header h1 {
  color: white;
  margin-bottom: 16px;
}

.landing-header .lead {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.25rem;
}

.beta-tag {
  font-size: 0.4em;
  background-color: rgba(255, 255, 255, 0.9);
  color: #13B2B3;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  vertical-align: middle;
  font-weight: bold;
  position: relative;
  top: -10px;
}

/* Optional: Add some subtle animation to the gradient */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.phone-mockup {
  position: relative;
  width: 280px;
  height: 560px;
  margin: 2rem auto;
  border-radius: 40px;
  padding: 5px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.phone-screen {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 32px;
}

/* Add this new style for the landing page background */
.landing-page {
  background: linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 100%);
  min-height: 100vh;
} 