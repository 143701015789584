/* Admin-specific navigation styles */
.admin-nav {
    margin-bottom: 0 !important;  /* Override any default margins */
    position: relative !important; /* Override any fixed/absolute positioning */
    bottom: auto !important;      /* Override any bottom positioning */
}

/* Container specific to admin pages */
.admin-container {
    padding-bottom: 1rem;
}

/* Admin card styles */
.admin-card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Admin dashboard metrics */
.admin-metric {
    font-size: 2rem;
    font-weight: bold;
    color: #007bff;
}

.search-results {
    max-height: 300px;
    overflow-y: auto;
}

.search-results div:hover {
    background-color: #f8f9fa;
}
